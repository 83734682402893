const config = {
    host: (process.env.NODE_ENV === 'production') ? ' https://daily-bol.com' : 'http://192.168.100.106:3061',
    homePage: (process.env.NODE_ENV === 'production') ? ' https://daily-bol.com' : 'http://192.168.100.106:3061',
    api: 'api/',
    apiSocket: 'daily::1.2',
    nameDir: 'daily',
    package: 'com.dailybol.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyBsBcXGsg4tso3xwActOEuJ5eNtvYhm7Ec',
    appName: 'Daily',
    provider: '',
    colorPrimary: '#010100',
    colorDark: '#32B8DD',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'com.dailybol.passenger',
    playStoreDriverId: 'com.dailybol.driver',
    appStorePassengerId: '6471515833',
    appStoreDriverId: '6471516852',
    email: "SPICH21@GMAIL.COM",
};
export default config;
